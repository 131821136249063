/*
 * 업무구분 : 고객
 * 화 면 명 : MSPCM050M
 * 화면설명 : 고객등록동의 진입
 * 접근권한 : 일반사용자
 * 작 성 일 : 2023.02.21
 * 작 성 자 : sh.park2022

 * 해당화면 진입 할 경우 route.param 필수값 { srnId: '송신화면ID' }
 * 없다면, 뒤로가기로 진입한 것으로 판단
 */
<template>
  <ur-page-container title="고객등록동의" :show-title="true" type="subpage" class="msp" @on-header-left-click="fn_HistoryBack">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-radio-list2 mb30">
        <div class="list-item-wrapper" style="overflow: unset;">
          <div class="list-item pt29 pb28" :class="{'on' : aprvType === '01'}" @click="aprvType='01'"><!-- LINE:수정 20230214 -->
            <div class="list-item__main">
              <mo-radio v-model="aprvType" value="01" checked></mo-radio>
              <div class="list-item__contents fs17rem " :class="{'crTy-blue3' : aprvType === '01'}">모바일 동의(휴대폰/카카오/신용카드)</div>
            </div>
          </div>
          <div class="list-item pt29 pb28" :class="{'on' : aprvType === '02'}" @click="aprvType='02'"><!-- LINE:수정 20230214 -->
            <div class="list-item__main">
              <mo-radio v-model="aprvType" value="02"></mo-radio>
              <div class="list-item__contents fs17rem " :class="{'crTy-blue3' : aprvType === '02'}">지류동의서</div>
            </div>
          </div>
          <div class="list-item pt16 pb16" :class="{'on' : aprvType === '03'}" @click="aprvType='03'">
            <div class="list-item__main">
              <mo-radio v-model="aprvType" value="03"></mo-radio>
              <div class="list-item__contents fs17rem " :class="{'crTy-blue3' : aprvType === '03'}">
                <div class="tit">비대면 고객동의</div>
                <ul class="bullet-type-3">
                  <li class="fexTy5 mt10">
                    <span class="fs14rem crTy-bk7">비대면 동의란?</span>
                    <span class="ns-tool-tip ml10">
                      <mo-icon id="location0" icon="msp-tool-tip">
                      </mo-icon>
                      <mo-tooltip target-id="location0" class="tool-tip-box img-type-1">
                        <b class="fs14rem mb10">비대면 동의란?</b>
                        <div class="mt10"><img src="@/assets/img/content/img_area.png" style="width: 100%; height: 100%" alt="비대면 동의란 이미지" /></div>
                        <div class="fs14rem mt10">고객이 동의 절차를 마치면 자동으로 동의 완료됩니다.</div>
                        <div class="fs14rem">동의 요청을 보내면 고객에게 동의 웹페이지 링크가 발송되며, 고객이 직접 동의처리를 진행합니다.</div>
                      </mo-tooltip>
                    </span>
                  </li>
                </ul>
              </div>
              <mo-button color="normal" shape="border" size="small" class="black" @click="fn_BtnClick">발송이력</mo-button>
            </div>
            <div v-if="showTooltip" class="chat-bubble type-M1 blu2" style="bottom: -25px;">
              <div class="exp">이제는 신규고객에게도<br>카카오톡으로 동의를 요청할 수 있어요!</div>
              <mo-icon icon="msp-close-bold" class="chat-bubble-close" @click="fn_CloseTooltip" />
            </div>
          </div>
        </div>
      </ur-box-container>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative mb60">
        <div class="relative-div">
          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_MovePage">다음</mo-button>
        </div>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'

export default {
/************************************************************************************************
 * Vue 파일 속성 정의 영역
************************************************************************************************/
  // 현재 화면명
  name: 'MSPCM050M',
  // 현재 화면 ID
  screenId: 'MSPCM050M',
  /************************************************************************************************
   * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
   return {
      title: '고객등록동의 진입',
      lv_UserInfo: {}, // 사용자 정보
      lv_RouteParam: {}, // 전달받은 라우터 파라미터

      preSrnId: '',   // 송신 화면ID
      aprvType: '01',  // 고객등록타입 (default: '01'/모바일 고객등록)
      showTooltip: true // 비대면동의 툴팁
    }
  },
  /************************************************************************************************
   * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  created() {
    console.log('■■■■■■ MSPCM050M ■■■■■■')
    this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo

    this.fn_Init()

    window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', 0)
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
  },
  mounted() {
    // 툴팁
    this.showTooltip = !localStorage.getItem('READ_MSPCM050M_TOOLTIP');

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM050M')
  },
  destroyed() {
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
  },
  /************************************************************************************************
   * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /***********************************************
     * @function fn_Init
     * @notice   화면 진입시, 최초 실행 함수
    ***********************************************/
    fn_Init () {
      let preSrnObj = window.vue.getStore('cmStore').getters.getState.preSrnObjCM050M
      this.lv_RouteParam.srnId = !_.isEmpty(preSrnObj) ? preSrnObj.srnId : 'MSPBC002M'

      if (!this.$bizUtil.isEmpty(this.$route.params.srnId)) {
        let rtnParam = this.$route.params.rtnParam

        this.lv_RouteParam = {
          srnId: this.$route.params.srnId ? this.$route.params.srnId : '',
          custNm: !this.$bizUtil.isEmpty(this.$route.params.custNm) ? this.$route.params.custNm : '',
          infoPrcusCnsntTypCd: this.$route.params.infoPrcusCnsntTypCd,
          aprvType: this.$route.params.aprvType,
          rtnParam: !_.isEmpty(rtnParam) ? rtnParam : {}
        }

        // 고객등록 Store 진입 전 화면 저장
        window.vue.getStore('cmStore').dispatch('PRE_SRN_OBJ_CM050M', this.lv_RouteParam)

        if (this.lv_RouteParam.aprvType === '01') {
          this.aprvType = this.lv_RouteParam.aprvType
          this.fn_MovePage()
        }

      } else { // 라우터 파라미터가 없으면 뒤로가기로 진입한 것으로 판단
        /* // 백키 이동시 보장가입설계 화면으로 무한 로딩되는 버그 수정을 위해 임시로 주석처리함 (2023.07.20)

        // 모바일 고객등록으로 바로 진입했을 경우
        if (!_.isEmpty(preSrnObj)) {
          if (preSrnObj.aprvType === '01' && !this.$bizUtil.isEmpty(preSrnObj.srnId)) {
            this.$router.push({ name: preSrnObj.srnId, params: preSrnObj.rtnParam })
          }
        }
        */
      }

      this.preSrnId = !this.$bizUtil.isEmpty(this.lv_RouteParam.srnId) ? this.lv_RouteParam.srnId : ''
      this.lv_RouteParam.custNm = !this.$bizUtil.isEmpty(this.$route.params.custNm) ? this.$route.params.custNm : ''
    },
    /***********************************************
     * @function fn_MovePage
     * @name     화면전환
     * @notice   - 모바일동의: MSPCM001M 
     *           - 서면 동의 : MSPCM040M
     *           - 비대면 고객동의: MSPCM133M
    ***********************************************/
    fn_MovePage () {
      let pParam = {
        srnId: this.$options.screenId,     // 진입 화면ID
        custNm: this.lv_RouteParam.custNm  // 고객명
      }

      pParam = !_.isEmpty(this.lv_RouteParam) ? this.lv_RouteParam : {}
      let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M
      if (this.aprvType === '01') {
        window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', pageCnt+1)
        this.$router.push({ name: 'MSPCM001M', params: pParam })
      } else if (this.aprvType === '02') {
        window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', pageCnt+1)
        this.$router.push({ name: 'MSPCM040M', params: pParam })
      } else if (this.aprvType === '03') {
        window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', pageCnt+1)
        this.$router.push({ name: 'MSPCM133M', params: pParam })
      }
    },
    /*********************************************************
     * @function fn_CloseTooltip
     * @notice   툴팁 닫기
     *********************************************************/
    fn_CloseTooltip() {
      this.showTooltip = false;
      localStorage.setItem('READ_MSPCM050M_TOOLTIP', true);
    },
    /***********************************************
     * @function fn_BtnClick
     * @name     발송이력
     * @notice   비대면동의 진행현황 화면 전환
    ***********************************************/
    fn_BtnClick () {
      this.$router.push({name: 'MSPCM135M'})
    },
    /******************************************************************************
     * @function fn_HistoryBack
     * @notice   뒤로가기 버튼
    ******************************************************************************/
    fn_HistoryBack () {
      let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M
      window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', pageCnt-1)
      
      if (this.$route.query?._isMenuItem) {
        this.$router.push({ name: 'MSPBC002M' })
        return
      }
      this.$router.go(-1)
    },
  } // ::Methods End
}
</script>

